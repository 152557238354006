import { KeyValuePair } from 'types/helpers/flattenObject';

export function formatResponse(arr: Array<KeyValuePair>) {
  const unwantedKeys = [
    'device.name',
    'device.name',
    'device.applicationID',
    'device.deviceProfileID',
    'device.skipFCntCheck',
    'device.referenceAltitude',
    'device.isDisabled',
    'deviceStatusBattery',
    'deviceStatusMargin',
    'deviceActivation.fNwkSIntKey',
    'deviceActivation.appSKey',
    'deviceActivationn.wkSEncKey',
    'deviceActivation.devEUI',
    'deviceActivation.sNwkSIntKey',
    'deviceActivation.nwkSEncKey',
    'deviceProfile.id',
    'deviceProfile.organizationID',
    'deviceProfile.networkServerID',
    'deviceProfile.supportsClassB',
    'deviceProfile.classBTimeout',
    'deviceProfile.pingSlotPeriod',
    'deviceProfile.pingSlotDR',
    'deviceProfile.pingSlotFreq',
    'deviceProfile.supportsClassC',
    'deviceProfile.classCTimeout',
    'deviceProfile.macVersion',
    'deviceProfile.regParamsRevision',
    'deviceProfile.rxDelay1',
    'deviceProfile.rxDROffset1',
    'deviceProfile.rxDataRate2',
    'deviceProfile.rxFreq2',
    'deviceProfile.maxEIRP',
    'deviceProfile.maxDutyCycle',
    'deviceProfile.supportsJoin',
    'deviceProfile.rfRegion',
    'deviceProfile.supports32BitFCnt',
    'deviceProfile.payloadCodec',
    'deviceProfile.payloadEncoderScript',
    'deviceProfile.payloadDecoderScript',
    'deviceProfile.geolocBufferTTL',
    'deviceProfile.geolocMinBufferSize',
    'deviceProfile.uplinkInterval',
    'createdAt',
    'updatedAt',
  ];

  const resultArray = arr
    .filter(obj => {
      return !unwantedKeys.some(key => obj.key === key);
    })

    .map(obj => {
      const relevantKeyInfo = obj.key.substring(obj.key.lastIndexOf('.') + 1);
      const newKey = relevantKeyInfo;
      return { ...obj, key: newKey };
    });
  return resultArray;
}
