import jwt_decode from 'jwt-decode';
import { AuthJwtPayload } from 'types/auth/model';

export function isTokenExpired(token: string) {
  let payload = null;

  try {
    payload = jwt_decode<AuthJwtPayload>(token);
  } catch (err: any) {
    return true; // Interpret invalid token as expired
  }

  return Math.floor(Date.now() / 1000) >= payload.exp;
}
