import type { SourceObject, KeyValuePair } from 'types/helpers/flattenObject';

export function flattenObject(
  src: SourceObject<any>,
  dest: Array<KeyValuePair> = [],
  path: string = ''
): Array<KeyValuePair> {
  for (const [key, value] of Object.entries(src)) {
    if (typeof value === 'undefined' || value === null) {
      continue;
    }

    const keyPath = `${path}${path.length ? '.' : ''}${key}`;

    switch (typeof value) {
      case 'object':
        flattenObject(value, dest, keyPath);
        break;
      default:
        dest.push({
          key: keyPath,
          value: String(value),
        });
    }
  }

  return dest;
}
