import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from 'store/configureStore';
import RootRouter from 'routes/RootRouter';
import reportWebVitals from 'reportWebVitals';
import 'index.css';

const theme = createTheme();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <CssBaseline />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={RootRouter} />
      </ThemeProvider>
    </Provider>
  </>
);

reportWebVitals();
