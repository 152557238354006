import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'store/auth/authSlice';
import { IWithChildren } from 'types/global';
import { isTokenExpired } from 'helpers/token';

interface ProtectedRouteProps extends IWithChildren {}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(tokenSelector);

  useEffect(() => {
    if (typeof token !== 'string' || isTokenExpired(token)) {
      const url = location.pathname + location.search;
      const encodedUrl = encodeURIComponent(url);

      navigate(`/login?redirectUrl=${encodedUrl}`);
    }
  }, [location.pathname, location.search, navigate, token]);

  return <>{children}</>;
}
