import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/configureStore';
interface AuthState {
  token?: string;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    removeToken: state => {
      delete state.token;
    },
  },
});

export const tokenSelector = (state: RootState) => state.auth.token;

export const { addToken, removeToken } = authSlice.actions;

export default authSlice.reducer;
