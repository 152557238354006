import { useEffect } from 'react';
import { BrowserQRCodeReader, IScannerControls } from '@zxing/browser';
import { Result } from '@zxing/library';

export type OnResultCallback = (
  isSuccess: boolean,
  data: Result | Error,
  controls?: IScannerControls
) => void;

export interface UseQRScannerHookProps {
  streamConstraints: MediaStreamConstraints;
  onResult: OnResultCallback;
  delayBetweenScans?: number;
  previewElement?: HTMLVideoElement | string;
}

export function useQRScanner({
  streamConstraints,
  onResult,
  delayBetweenScans: delayBetweenScanAttempts = 500,
  previewElement,
}: UseQRScannerHookProps) {
  useEffect(() => {
    let mounted = true;

    const isSupported =
      typeof navigator !== 'undefined' && !!navigator.mediaDevices;

    if (!isSupported) {
      const errorMsg = 'Browser does not support MediaDevices API.';
      onResult(false, new Error(errorMsg));
      return;
    }

    const reader = new BrowserQRCodeReader(undefined, {
      delayBetweenScanAttempts,
      tryPlayVideoTimeout: 50,
    });

    reader
      .decodeFromConstraints(
        streamConstraints,
        previewElement,
        (
          result: Result | undefined,
          error: Error | undefined,
          controls: IScannerControls
        ) => {
          // The zxing library will always call this callback at least once if it gets
          // to the async scan loop without throwing an error first. The camera closing
          // bug can be fixed by checking here if this effect is still mounted and
          // stopping the async scan loop if not.
          if (!mounted) {
            controls.stop();
          }

          const isSuccess =
            typeof result !== 'undefined' && typeof error === 'undefined';

          // At run time, one param is always defined, the other is always undefined.
          // See zxing library code. TS doesn't know this, hence the cast.
          onResult(
            isSuccess,
            isSuccess ? result : (error as Result | Error),
            controls
          );
        }
      )
      .catch((error: Error) => {
        onResult(false, error);
      });

    return () => {
      mounted = false;
    };
  }, [streamConstraints, previewElement, delayBetweenScanAttempts, onResult]);
}
