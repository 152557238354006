import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/configureStore';

interface DeviceStatusState {
  devices: { [key: string]: boolean };
}

const initialState: DeviceStatusState = {
  devices: {},
};

export const deviceSlice = createSlice({
  name: 'deviceStatus',
  initialState,
  reducers: {
    addDevice: (state, action: PayloadAction<string>) => {
      if (typeof state.devices[action.payload] === 'undefined') {
        state.devices[action.payload] = true;
      }
    },
    removeDevice: (state, action: PayloadAction<string>) => {
      delete state.devices[action.payload];
    },
  },
});

export const deviceSelector = (state: RootState) => state.deviceStatus.devices;

export const { addDevice, removeDevice } = deviceSlice.actions;

export default deviceSlice.reducer;
