import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { removeDevice } from 'store/device/deviceSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceStatusIndicator from './DeviceStatusIndicator';
import { Link } from 'react-router-dom';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';

const Device = ({ deviceEUI }: any) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(removeDevice(deviceEUI));
  };

  return (
    <>
      <Divider />
      <Paper>
        <ListItemButton
          key={deviceEUI}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <DeviceStatusIndicator deviceEUI={deviceEUI} />{' '}
            <Link
              to={`/device-detail/${deviceEUI}`}
              style={{ textDecoration: 'none', color: 'black' }}
            >
              <Button
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  padding: 0,
                  marginLeft: 15,
                }}
              >
                {deviceEUI}
              </Button>
            </Link>
          </Box>
          <IconButton edge="end" aria-label="delete" onClick={handleClick}>
            <DeleteIcon />
          </IconButton>
        </ListItemButton>
      </Paper>
    </>
  );
};

export default Device;
