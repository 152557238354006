import { useQRScanner, OnResultCallback } from 'hooks/QRScanner';

export interface QRScannerProps {
  streamConstraints: MediaStreamConstraints;
  onResult: OnResultCallback;
  delayBetweenScans?: number;
  videoId?: string;
}

export function QRScanner({
  streamConstraints,
  onResult,
  delayBetweenScans,
  videoId: previewElement = 'qr-scanner-video',
}: QRScannerProps) {
  useQRScanner({
    streamConstraints,
    onResult,
    delayBetweenScans,
    previewElement,
  });

  return (
    <div>
      <video id={previewElement} />
    </div>
  );
}
