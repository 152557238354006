import { createBrowserRouter, Outlet } from 'react-router-dom';
import { ProtectedRoute } from 'components/routing/ProtectedRoute';
import Login from 'pages/Login';
import DeviceList from 'pages/DeviceListPage';
import DeviceDetail from 'pages/DeviceDetail';
import ErrorPage from 'pages/ErrorPage';
import Scanner from 'pages/Scanner';

const publicRoutes = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '/',
    element: <Login />,
  },
];

const protectedRoutes = [
  {
    path: 'device-list',
    element: <DeviceList />,
  },
  {
    path: 'device-detail/:deviceEUI',
    element: <DeviceDetail />,
  },
  {
    path: 'scanner',
    element: <Scanner />,
  },
];

export default createBrowserRouter([
  {
    element: <Outlet />,
    children: [
      ...publicRoutes,
      {
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        children: protectedRoutes,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);
