import Device from 'app/components/Device';
import { useSelector } from 'react-redux';
import { deviceSelector } from 'store/device/deviceSlice';
import List from '@mui/material/List';
import NavBar from 'components/NavBar';
import Box from '@mui/material/Box';

export default function DeviceListPage() {
  const deviceList = useSelector(deviceSelector);

  return (
    <>
      <NavBar title={'Device List'} />
      {deviceList ? (
        <List>
          {Object.keys(deviceList).map((deviceEUI: string) => (
            <Device deviceEUI={deviceEUI} key={deviceEUI} />
          ))}
        </List>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <div> No devices found </div>
        </Box>
      )}
    </>
  );
}
