import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {`© ${new Date().getFullYear()} `}
      <Link
        reloadDocument
        color="inherit"
        to="https://www.invisible-systems.com/"
      >
        Invisible Systems
      </Link>
    </Typography>
  );
}
