import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  Logout,
  Mail,
  Menu,
  ArrowBack,
} from '@mui/icons-material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { AppBarProps } from '@mui/material/AppBar';
import { removeToken } from 'store/auth/authSlice';
import { useDispatch } from 'react-redux';

const drawerWidth = 240;
interface StyledAppBarProps extends AppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<StyledAppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function NavBar({ title }: any) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    dispatch(removeToken());
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const currentUrl = location.pathname;

  const RenderDeviceListNavBar = currentUrl.includes('/device-detail');

  const deviceListNavBar = (
    <List>
      {['Device List'].map((text, index) => (
        <ListItem key={text} disablePadding>
          <Link
            to="/device-list"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <Logout /> : <Mail />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </Link>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 7,
        justifyContent: 'center',
      }}
    >
      <CssBaseline />
      <StyledAppBar open={open} style={{ backgroundColor: '#EB008C' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              mr: 2,
              ...(open && { display: 'none' }),
            }}
          >
            <Menu />
          </IconButton>
          <IconButton color="inherit" onClick={goBack}>
            <ArrowBack />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: 'center', paddingRight: '60px' }}
          >
            {title}
          </Typography>
          <Link
            to={'/Scanner'}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <IconButton color="inherit">
              <QrCodeScannerIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </StyledAppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {RenderDeviceListNavBar && deviceListNavBar}
        <Divider />
        <List>
          {['Log Out'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  {index % 2 === 0 ? <Logout /> : <Mail />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
