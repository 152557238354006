import CircularProgress from '@mui/material/CircularProgress';
import { useGetDeviceQuery } from 'services/deviceApi';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Box } from '@mui/system';

const DeviceStatusIndicator = ({ deviceEUI }: any) => {
  const { data, isLoading } = useGetDeviceQuery(deviceEUI);
  const loraWANDevice = data?.original.device;
  if (isLoading) {
    return <CircularProgress color="inherit" />;
  }
  const isOnline =
    loraWANDevice &&
    !loraWANDevice.isDisabled &&
    isRecentlySeen(data?.original.lastSeenAt);

  function isRecentlySeen(lastSeenAt: string) {
    if (!lastSeenAt) {
      return false;
    }

    const lastSeenTime = new Date(lastSeenAt);
    const currentTime = new Date();
    const timeDiffSeconds =
      (currentTime.getTime() - lastSeenTime.getTime()) / 1000;
    return timeDiffSeconds <= 1800; // 1800 seconds = 30 minutes
  }

  const color = isOnline ? 'green' : 'red';

  return (
    <>
      <Box>
        <Brightness1Icon style={{ color }} />
      </Box>
    </>
  );
};

export default DeviceStatusIndicator;
