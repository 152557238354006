import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Copyright } from 'components/Copyright';
import { useLoginMutation } from 'services/authApi';
import { addToken, removeToken, tokenSelector } from 'store/auth/authSlice';
import { useState, useEffect } from 'react';
import { LoginRequest } from 'types/auth/api';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { isTokenExpired } from 'helpers/token';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector(tokenSelector);

  useEffect(() => {
    if (typeof token === 'string') {
      if (!isTokenExpired(token)) {
        // Authenticated users are redirected on page load.
        navigate(searchParams.get('redirectUrl') ?? '/device-list');
      } else {
        // If we have an old token, remove it from the store.
        dispatch(removeToken());
      }
    }
  }, [token, dispatch, navigate, searchParams]);

  const onLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const credentials: LoginRequest = {
      email,
      password,
    };

    setIsLoading(true);

    try {
      const result = await login(credentials).unwrap();

      if (typeof result.token !== 'string') {
        throw Error('Not authenticated.');
      }

      dispatch(addToken(result.token));
      navigate(searchParams.get('redirectUrl') ?? '/device-list');
    } catch (error: any) {
      setError(true);
    }

    setIsLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, backgroundColor: '#EB008C' }}>
          <LockOutlinedIcon sx={{ color: '#fff' }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email} // Set the value to the email state
            onChange={e => setEmail(e.target.value)} // Update the email state
            error={error}
          />
          <Box sx={{ color: 'red' }}>
            {error && <> Username or password is incorrect </>}
          </Box>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password} // Set the value to the password state
            onChange={e => setPassword(e.target.value)} // Update the password state
            error={error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ backgroundColor: '#EB008C' }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <> Sign In </>
            )}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
