import { Box, Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error: any = useRouteError();

  return (
    <Box sx={{ margin: 'auto', padding: '100px' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2" sx={{ textAlign: 'center', margin: 'dense' }}>
          Oops!
        </Typography>
        <Typography
          sx={{ textAlign: 'center', padding: 'auto', margin: 'dense' }}
        >
          An unexpected error has occurred.
        </Typography>
        <Typography
          sx={{ textAlign: 'center', padding: 'auto', margin: 'dense' }}
        >
          <i>{error.statusText || error.message}</i>
        </Typography>
      </Box>
    </Box>
  );
}
