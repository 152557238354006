import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import {
  useGetDeviceActivationQuery,
  useGetDeviceProfileQuery,
  useGetDeviceQuery,
} from 'services/deviceApi';
import { useParams } from 'react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import NavBar from 'components/NavBar';
import {
  FlattenedGetDeviceResponse,
  FlattenedGetDeviceActivationResponse,
  FlattenedGetDeviceProfileResponse,
} from 'types/device/api';
import Divider from '@mui/material/Divider';
import { formatResponse } from 'helpers/formatResponse';

export default function DeviceDetail() {
  const { deviceEUI } = useParams();

  const { data: deviceData } = useGetDeviceQuery(
    typeof deviceEUI === 'string' ? deviceEUI : skipToken
  );

  const { data: activationData } = useGetDeviceActivationQuery(
    typeof deviceEUI === 'string' ? deviceEUI : skipToken
  );

  const { data: profileData } = useGetDeviceProfileQuery(
    deviceData?.original?.device.deviceProfileID ?? skipToken
  );

  if (!deviceData && !activationData && !profileData) {
    return (
      <>
        <NavBar title={'Device Detail'} />
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 13 }}>
          <Typography>No data found.</Typography>
        </Box>
      </>
    );
  }

  const deviceRows: FlattenedGetDeviceResponse = deviceData?.transformed ?? [];
  const formattedDevice = formatResponse(deviceRows);
  const activationRows: FlattenedGetDeviceActivationResponse =
    activationData?.transformed ?? [];
  const formattedActivation = formatResponse(activationRows);
  const profileRows: FlattenedGetDeviceProfileResponse =
    profileData?.transformed ?? [];
  const formattedProfile = formatResponse(profileRows);

  return (
    <>
      <NavBar title={'Device Detail'} />
      <Box sx={{ paddingTop: 1.5 }}>
        {deviceData && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Device Data</Typography>
            </AccordionSummary>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <Divider />
                <>
                  {formattedDevice.map((row, index) => (
                    <Box key={index} sx={{ padding: '9px' }}>
                      <Typography
                        sx={{ textAlign: 'left', paddingLeft: '10px' }}
                      >
                        <strong>{row.key}:</strong>
                      </Typography>
                      <Typography
                        sx={{ textAlign: 'left', paddingLeft: '10px' }}
                      >
                        {' '}
                        {row.value}
                      </Typography>
                      <Divider sx={{ marginTop: '10px' }} />
                    </Box>
                  ))}
                </>
              </Table>
            </TableContainer>
          </Accordion>
        )}
        {activationData && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Activation Data</Typography>
            </AccordionSummary>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <Divider />
                <>
                  {formattedActivation.map((row, index) => (
                    <Box key={index} sx={{ padding: '9px' }}>
                      <Typography
                        sx={{ textAlign: 'left', paddingLeft: '10px' }}
                      >
                        {' '}
                        <strong>{row.key}:</strong>
                      </Typography>
                      <Typography
                        sx={{ textAlign: 'left', paddingLeft: '10px' }}
                      >
                        {' '}
                        {row.value}
                      </Typography>
                      {index !== deviceRows.length - 1 && (
                        <Divider sx={{ marginTop: '10px' }} />
                      )}
                    </Box>
                  ))}
                </>
              </Table>
            </TableContainer>
          </Accordion>
        )}
        {profileData && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Profile Data</Typography>
            </AccordionSummary>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <Divider />
                <>
                  {formattedProfile.map((row, index) => (
                    <Box key={index} sx={{ padding: '9px' }}>
                      <Typography
                        sx={{ textAlign: 'left', paddingLeft: '10px' }}
                      >
                        {' '}
                        <strong>{row.key}:</strong>
                      </Typography>
                      <Typography
                        sx={{ textAlign: 'left', paddingLeft: '10px' }}
                      >
                        {' '}
                        {row.value}
                      </Typography>
                      <Divider sx={{ marginTop: '10px' }} />
                    </Box>
                  ))}
                </>
              </Table>
            </TableContainer>
          </Accordion>
        )}
      </Box>
    </>
  );
}
