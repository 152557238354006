import { createApi } from '@reduxjs/toolkit/query/react';
import { flattenObject } from 'helpers/flattenObject';
import {
  GetDeviceProfileResponse,
  GetDeviceResponse,
  GetDeviceActivationResponse,
  FlattenedGetDeviceResponse,
  FlattenedGetDeviceActivationResponse,
  FlattenedGetDeviceProfileResponse,
} from 'types/device/api';
import { TransformedPair } from 'types/global';
import { baseQuery } from './baseQuery';

export const deviceApi = createApi({
  reducerPath: 'deviceApi',
  baseQuery,
  endpoints: builder => ({
    getDevice: builder.query<
      TransformedPair<GetDeviceResponse, FlattenedGetDeviceResponse>,
      string
    >({
      query: deviceEui => ({
        url: `/devices/${deviceEui}`,
        method: 'GET',
      }),
      transformResponse: (
        response: GetDeviceResponse
      ): TransformedPair<GetDeviceResponse, FlattenedGetDeviceResponse> => ({
        original: response,
        transformed: flattenObject(response),
      }),
    }),
    getDeviceActivation: builder.query<
      TransformedPair<
        GetDeviceActivationResponse,
        FlattenedGetDeviceActivationResponse
      >,
      string
    >({
      query: deviceEui => ({
        url: `/devices/${deviceEui}/activation`,
        method: 'GET',
      }),
      transformResponse: (
        response: GetDeviceActivationResponse
      ): TransformedPair<
        GetDeviceActivationResponse,
        FlattenedGetDeviceActivationResponse
      > => ({ original: response, transformed: flattenObject(response) }),
    }),
    getDeviceProfile: builder.query<
      TransformedPair<
        GetDeviceProfileResponse,
        FlattenedGetDeviceProfileResponse
      >,
      string
    >({
      query: deviceProfile => ({
        url: `/device-profiles/${deviceProfile}`,
        method: 'GET',
      }),
      transformResponse: (
        response: GetDeviceProfileResponse
      ): TransformedPair<
        GetDeviceProfileResponse,
        FlattenedGetDeviceProfileResponse
      > => ({ original: response, transformed: flattenObject(response) }),
    }),
  }),
});

export const {
  useGetDeviceQuery,
  useGetDeviceActivationQuery,
  useGetDeviceProfileQuery,
} = deviceApi;
