import { configureStore } from '@reduxjs/toolkit';
import deviceStatusReducer from 'store/device/deviceSlice';
import authReducer from 'store/auth/authSlice';
import { deviceApi } from 'services/deviceApi';
import { authApi } from 'services/authApi';

const LS_KEY_NAME_STATE = 'globalAppState';

const persistedState = localStorage.getItem(LS_KEY_NAME_STATE);

export const store = configureStore({
  reducer: {
    deviceStatus: deviceStatusReducer,
    auth: authReducer,
    [deviceApi.reducerPath]: deviceApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  preloadedState: persistedState ? JSON.parse(persistedState) : {},
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(deviceApi.middleware)
      .concat(authApi.middleware),
});

store.subscribe(async () => {
  localStorage.setItem(LS_KEY_NAME_STATE, JSON.stringify(store.getState()));
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
