import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QRScanner } from 'components/QRScanner';
import { addDevice } from 'store/device/deviceSlice';
import { useQRScanner } from 'hooks/QRScanner';
import QRMenuBar from 'components/QRMenuBar';
import Box from '@mui/material/Box';
import styles from './scanner.module.css';

export default function Scanner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const streamConstraints = {
    audio: false,
    video: { facingMode: 'environment' },
  };

  function handleScanResult(isSuccess: boolean, data: any) {
    if (isSuccess) {
      dispatch(addDevice(data.text));
      navigate('/device-list');
    }
  }

  useQRScanner({
    streamConstraints,
    onResult: handleScanResult,
  });

  return (
    <Box>
      <QRMenuBar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          className={styles.videoContainer}
          sx={{
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          <QRScanner
            streamConstraints={streamConstraints}
            onResult={handleScanResult}
          />
        </Box>
      </Box>
    </Box>
  );
}
