import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { RootState } from 'store/configureStore';
import { tokenSelector, removeToken } from 'store/auth/authSlice';

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const query = fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }) => {
      const token = tokenSelector(getState() as RootState);
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  });

  const result: any = query(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch(removeToken());
  }

  return result;
};
