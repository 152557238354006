import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

export default function QRMenuBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: '#EC018C' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link
            to="/device-list"
            style={{ textDecoration: 'none', color: '#fff' }}
          >
            <Button variant="outlined" color="inherit" endIcon={<CloseIcon />}>
              Cancel Scan
            </Button>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
